import styled from 'styled-components'

export const BlogDescription = styled.div`
    border-bottom: 1px solid #D7D7D7;
    border-top: 1px solid #D7D7D7;
    padding-bottom: 10px;
    padding-top: 10px;
    p {
        
        font-size:15px;
    }
    a { 
        color:black;
      }
`